<template>
  <v-hover :disabled="!action">
    <template #default="{ hover }">
      <v-card flat tile>
        <v-img
          v-if="url"
          :src="url"
          contain
        >
          <template #placeholder>
            <v-sheet :style="{ height: '100%' }" class="d-flex justify-center align-center full-width flex-grow-1">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
              ></v-progress-circular>
            </v-sheet>
          </template>
        </v-img>

        <v-overlay 
          v-if="hover"
          absolute
        >
          <v-btn
            @click="showDeleteDialog()"
            outlined
            small
            icon
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-overlay>

        <!-- CONFIRM DELETE -->
        <confirm-delete 
          @cancel="closeDeleteDialog()"
          @confirmed="deleteConfirmed()"
          :deleting="$store.state.file_upload.status.deleting"
          message="Delete image?"
          :show="deleteDialog"
        />
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import firebase from 'firebase'
var storage  = firebase.storage()

export default {
  props: {
    image: String,
    folder: String,
    action: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      url: null,
      deleteDialog: false,
      deleteMessage: null,
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'image': {
      handler(value) {
        if (value) this.getUrl(`${this.folder}/${value}`)
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getUrl(value) {
      storage.ref(value)
      .getDownloadURL()
      .then(url => {
        this.url = url
      })
      .catch(error => {
        console.log(error.message)
      })
    },

    showDeleteDialog() {
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.resolve(
        this.$store.dispatch('file_upload/deleteFile', {
          folder: 'forms',
          file: this.image
        })
      ).then(() => {
        this.url = null
        this.$emit('deleted')
        this.closeDeleteDialog()
      })
    }
  }
}
</script>